import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useModalData } from "hooks/useModalData";
import { NavHome } from './Nav'
import { HeaderHome } from './Header'
import { FooterHome } from './Footer'
import { ModalLogin } from './ModalLogin'
import { ModalPassword } from './ModalPassword'
import { ModalContacto } from './ModalContacto'
import { ModalRegistro } from './ModalRegistro'
import { ModalBasico } from './ModalBasico'
import { ModalProfesional } from './ModalProfesional'
import { ModalUsuarioInformacion } from './ModalUsuarioInformacion'
import { ModalInfoRadon } from './ModalInfoRadon'
import { useLang } from 'language';
import { ModalBasicoForm, ModalBasicoForm1, ModalBasicoForm2, ModalBasicoForm3 } from "./ModalBasicoForm";
import { ModalProfesionalForm, ModalProfesionalForm1, ModalProfesionalForm2, ModalProfesionalForm3 } from "./ModalProfesionalForm";
import { Footer } from "components/Footer/Footer";
import { AuthContext } from 'auth/AuthContext';

export const Home = ({openModal}) => {
    const locale = useLang();
    const { user: { lang } } = useContext(AuthContext)
    const [dataModal_Login, handleOpenModal_Login, handleCloseModal_Login] = useModalData()
    const [dataModal_Contacto, handleOpenModal_Contacto, handleCloseModal_Contacto] = useModalData()
    const [dataModal_Registro, handleOpenModal_Registro, handleCloseModal_Registro] = useModalData()
    const [dataModal_Password, handleOpenModal_Password, handleCloseModal_Password] = useModalData()
    const [dataModal_Profesional, handleOpenModal_Profesional, handleCloseModal_Profesional] = useModalData()
    const [dataModal_Basico, handleOpenModal_Basico, handleCloseModal_Basico] = useModalData()
    const [dataModal_Info, handleOpenModal_Info, handleCloseModal_Info] = useModalData()
    const [dataModal_Radon, handleOpenModal_Radon, handleCloseModal_Radon] = useModalData()

    useEffect(() => {
    }, [])

    const [showMore, setshowMore] = useState(false);


    return (
        <section className='PageIndex'>
            <NavHome
                handleOpenModal_Contacto={handleOpenModal_Contacto}
            />
            <HeaderHome
                handleOpenModal_Login={handleOpenModal_Login}
                handleOpenModal_Registro={handleOpenModal_Registro}
                handleOpenModal_Info={handleOpenModal_Info}
                handleOpenModal_Radon={handleOpenModal_Radon}
                openModal={openModal}
            />

            <section className='PageIndex_header'>
                <Container className="mb-5 px-4 pb-4 PageIndex--container">
                    <Row>
                        <Col md={6} className="text-center position-image">
                            <img src="/sensor.png" className="PageIndex_headerImg bg-image img-fluid" alt="img" />
                        </Col>
                        {/* <Col md={6}>
                            <div className="pt-3">
                                <h5 className="fw-bold">{locale('HOME_RADON_TEXT1')}</h5>
                                <p>
                                    {locale('HOME_RADON_DESCRIPCION1')}
                                </p>
                                <br />
                                <h5 className="fw-bold">{locale('HOME_RADON_TEXT2')}</h5>
                                <p>
                                    <span>{locale('HOME_RADON_DESCRIPCION2')}</span> (Bq/m<sup>3</sup>) <span>{locale('HOME_RADON_DESCRIPCION3')}</span>
                                </p>
                            </div>

                        </Col> */}
                    </Row>

                    <div className="row d-flex justify-content-center">
                        <Col md="12" className="text-center">
                            <h4 className="text-dark mb-3">{locale('HOME_SECTION1_TITLE')}</h4>
                        </Col>

                        <Col md={6} className="d-flex align-items-stretch">
                            <div className="section-border">
                                <span className="section-text-header">{locale('HOME_SECTION1_BOX1_TITLE')}</span>
                                <div className="p-2">
                                    <h4 className="mb-3 h4 fw-bold text-center">{locale('HOME_SECTION1_BOX1_SUBTITLE')}</h4>
                                    <p>
                                        {locale('HOME_SECTION1_BOX1_CONTENIDO')}
                                        {/* <a className="ms-1 text-decoration-none h6 text-primary" style={{ cursor: 'pointer' }} onClick={() => {
                                            handleOpenModal_Basico({
                                                action: 'EXTRA',
                                            })
                                        }}>[+]</a> */}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex align-items-stretch">
                            <div className="section-border">
                                <span className="section-text-header">{locale('HOME_SECTION1_BOX2_TITLE')}</span>
                                <div className="p-2">
                                    <h4 className="mb-3 h4 fw-bold text-center">{locale('P_TEST_RADON_PROFESIONAL_JUSTIFICACION_CTE')}</h4>
                                    <p>
                                        {locale('HOME_SECTION1_BOX2_CONTENIDO')}
                                        {/* <a className="ms-1 text-decoration-none h6 text-primary" style={{ cursor: 'pointer' }} onClick={() => {
                                            handleOpenModal_Profesional({
                                                action: 'EXTRA',
                                            })
                                        }}>[+]</a> */}
                                    </p>
                                </div>
                            </div>
                        </Col>

                        {
                            !showMore &&
                            <div className="col-12 text-center">
                                <Button variant="primary" onClick={() => setshowMore(true)}>{locale('VER_MAS')}</Button>
                            </div>
                        }

                    </div>

                    {
                        showMore &&
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm1 />
                                        </div>
                                    </div> 
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalProfesionalForm1 />
                                        </div>
                                      </div>  
                                </div>

                            </div>

                            <div className="row py-3">
                                <div className="col-md-6">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm2 />
                                        </div>
                                      </div>  
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalProfesionalForm2 />
                                        </div>
                                      </div>  
                                </div>
                            </div>

                            <div className="row py-3">
                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm3 />
                                        </div>
                                      </div>  
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                             <ModalProfesionalForm3 />
                                        </div>
                                      </div>  
                                </div>

                            </div>

                        </>
                    }

                    {
                        showMore &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <Button variant="primary" onClick={() => setshowMore(false)}>{locale('OCULTAR')}</Button>
                            </div>
                        </div>
                    }

                    {lang === 'ES' &&
                        <div className="col-12 text-center mt-4 mb-3">
                            <p className="text-dark fw-normal">{locale('COLABORACION')}:</p>
                            <img src="/CGATE_2023.gif" className="img-fluid" style={{ maxWidth: '300px' }} alt="CGATE 2023" />
                        </div>
                    }

                </Container>
            </section>
            
            <div className="mt-auto">
                <Footer />
            </div>

            {dataModal_Login.value &&
                <ModalLogin
                    data={dataModal_Login}
                    handleOpenModal_Password={handleOpenModal_Password}
                    handleClose={handleCloseModal_Login}
                />
            }

            {dataModal_Password.value &&
                <ModalPassword
                    data={dataModal_Password}
                    handleClose={handleCloseModal_Password}
                />
            }


            {dataModal_Contacto.value &&
                <ModalContacto
                    data={dataModal_Contacto}
                    handleClose={handleCloseModal_Contacto}
                />
            }

            {dataModal_Registro.value &&
                <ModalRegistro
                    data={dataModal_Registro}
                    handleClose={handleCloseModal_Registro}
                />
            }

            {dataModal_Profesional.value &&
                <ModalProfesional
                    data={dataModal_Profesional}
                    handleClose={handleCloseModal_Profesional}
                />
            }

            {dataModal_Basico.value &&
                <ModalBasico
                    data={dataModal_Basico}
                    handleClose={handleCloseModal_Basico}
                />
            }

            {dataModal_Info.value &&
                <ModalUsuarioInformacion
                    data={dataModal_Info}
                    handleClose={handleCloseModal_Info}
                />
            }

            {dataModal_Radon.value &&
                <ModalInfoRadon
                    data={dataModal_Radon}
                    handleClose={handleCloseModal_Radon}
                />
            }


        </section>
    );
};
